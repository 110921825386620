import React from 'react';
import Sorty from './components/Sorty';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Sync from "./components/Sync"

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Sorty />
      <Sync />
      <Footer />
    </div>
  );
}

export default App;