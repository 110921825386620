import React from 'react';
import {
  FaCoffee,
  FaDiscord,
  FaHeadset,
  FaMastodon,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#fff]'>Neoflab</h1>
        <p className='py-4'>We create free and open source application for manage your life and your pc.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
            <a href="https://discord.gg/b5huKsjjcD" target='_blank' rel="noreferrer"><FaDiscord className='hover:text-[#7289da]' size={30} /></a>
            <a href="https://www.buymeacoffee.com/neoftlab" target='_blank' rel="noreferrer"><FaCoffee className='hover:text-[yellow]' size={30} /></a>
            <a href="https://mastodon.social/@neoftlab" target='_blank' rel="noreferrer"><FaMastodon className='hover:text-[#6364ff]' size={30} /></a>
            <a href="mailto:support@neoftlab.dev"><FaHeadset  size={30} /></a>
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between mt-6'>
    <div>
        <h6 className='font-medium text-gray-400'>Products</h6>
        <ul>
            <li className='py-2 text-sm'>Sorty</li>
            <li className='py-2 text-sm'>Syncopia</li>
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-400'>Support</h6>
        <ul>
            <li className='py-2 text-sm'>Mail</li>
            <li className='py-2 text-sm'>Documentation</li>
            <li className='py-2 text-sm'>Guides</li>
            <li className='py-2 text-sm'>Codeberg Issues</li>
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-400'>Company</h6>
        <ul>
            <li className='py-2 text-sm'>About</li>
            <li className='py-2 text-sm'>Blog</li>
            <li className='py-2 text-sm'>Codeberg</li>
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-400'>Legal</h6>
        <ul>
            <li className='py-2 text-sm'>License</li>
            <li className='py-2 text-sm'>Policy</li>
            <li className='py-2 text-sm'>Terms</li>
        </ul>
    </div>
      </div>
    </div>
  );
};

export default Footer;
