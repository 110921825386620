import React from 'react';
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        {/* <p className='text-[#00df9a] font-bold p-2'>GROWING WITH DATA ANALYTICS</p> */}
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>Simplify your life.</h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold'>Open source app for </p>
          <ReactTyped
            className='md:text-5xl sm:text-4xl text-xl font-bold pl-2 text-[#02a89e]'
            strings={['sort photos', 'sync devices']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500'>Manage your life and your pc with open source application</p>
        <button className='bg-[#02a89e] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-blac '><a className='text-lg' href="#download">Try now</a></button>
      </div>
    </div>
  );
}

export default Hero;
