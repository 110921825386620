import React from 'react';
import SyncImg from '../assets/sync_image.jpg';

const Sync = () => {
  return (
    <div className='w-full bg-white py-16 px-4' id='download'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <div className='flex flex-col justify-center'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Syncopia</h1>
          <p>
          Syncopia is a software that allows you to efficiently synchronize your files offline. With its simple and intuitive interface, it automatically keeps your data up to date across multiple devices, even without an internet connection.           </p>
          <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Coming soon</button>
        </div>
        <img className='w-[500px] mx-auto my-4' src={SyncImg} alt='/' />
      </div>
    </div>
  );
};

export default Sync;
