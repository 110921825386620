import React from 'react';
import SortyImg from '../assets/sorty_img.jpg';

const Sorty = () => {
  return (
    <div className='w-full bg-white py-16 px-4' id='download'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={SortyImg} alt='/' />
        <div className='flex flex-col justify-center'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Sorty</h1>
          <p>
          Sorty is a software that allows you to efficiently sort your photos by date. With its simple and intuitive interface, it automatically organizes your images chronologically, making them easier to manage and view.
          </p>
          <button className='bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Coming soon</button>
        </div>
      </div>
    </div>
  );
};

export default Sorty;
